import React from "react"
import Layout from "../components/layout"
import Coffee from "../../static/coffee.png"
import Bread from "../../static/bread.png"
import Sandwich from "../../static/sandwich.png"

export default function Gastro() {
  return (
  <Layout>
    <div className='page-block gastro-content'>
    <img src={Coffee} alt='' className='gastro-block'/>
    <img src={Bread} alt='' className='gastro-block'/>
    <img src={Sandwich} alt='' className='gastro-block'/>
      <div className='gastro-text'>
          Každý apartmán je vybavený komplet zariadenou kuchyňou: sporák, rúra na pečenie, mikrovlnná rúra, chladnička s mrazničkou, riad, utierka, čistiace prostriedky, hubka.
          <br/>
          Apartmánový dom má aj vlastný obchod Potraviny-lahôdky, kde sa dá aj posedieť, zakúpiť raňajky ktoré na mieste môžete konzumovať, alebo zobrať na apartmán. Každé ráno tam nájdete množstvo lahôdok od mäsa, šalátov, chlebíčkov, sladkého a slaného pečiva, zeleniny, alko a nealkonápojov, cigariet...
          <br/>
          Otvorené pondelok ~ piatok: 6:00 ~ 18:00
      </div>
    </div>

  </Layout>
  );
}
    
  